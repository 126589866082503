<template>
  <div>
    <v-col>
      <v-btn @click="dialog = true" color="red" class="white--text"> <v-icon left>mdi-plus</v-icon>Добавить </v-btn>
    </v-col>
    <v-data-table :headers="headers" :items="tagListInCurrentCafe" disable-pagination hide-default-footer>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="deleteTagClick(item)" color="red" text>
          <v-icon left>mdi-delete-outline</v-icon>
          Удалить
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>Выберите тег</v-card-title>
        <v-data-table :headers="headers" :items="tags" disable-pagination hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-btn text color="red" @click="addCafeTagClick(item.token)">
              <v-icon left>mdi-plus</v-icon>
              Добавить</v-btn
            >
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="red" class="white--text my-1 ml-auto" @click="dialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Notification from '@/components/Notification';

export default {
  data() {
    return {
      dialog: false
    };
  },
  components: { Notification },
  computed: {
    headers() {
      return [
        { text: 'Название', value: 'nameRu' },
        { text: '', value: 'actions', sortable: false }
      ];
    },
    tagListInCurrentCafe() {
      let tags = [];
      if (this.tagList !== undefined && this.tagList.length > 0) {
        this.tagList.map((currTag) => {
          tags.push(this.tagList.find((tag) => tag.token === currTag.token));
        });
      }
      return tags;
    },
    tags() {
      let tagsArray = [];
      if (this.tagsList !== undefined && this.tagsList.length > 0) {
        this.tagsList.forEach((tag) => {
          if (this.tagList.find((t) => t.token === tag.token) === undefined) tagsArray.push(tag);
        });
      }
      return tagsArray;
    },
    ...mapGetters('tag', ['tagsList']),
    ...mapGetters('admin', ['tagList'])
  },
  methods: {
    getTagList() {
      this.fetchTagList();
    },
    deleteTagClick(item) {
      this.deleteCafeTag({
        cafeToken: this.$route.params.cafeToken,
        tagToken: item.token
      })
        .then(() => {
          this.closeDialog();
          // this.fetchMenuList(this.$route.params.cafeToken);
          // if (this.deleteItemCategoryResult === true) {
          this.showNotification(' успешно удалена', 'success', '2000', 'mdi-check');
          // }
        })
        .catch(() => {
          this.showNotification(`Возникла ошибка: `, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    addCafeTagClick(tagToken) {
      this.addCafeTag({
        cafeToken: this.$route.params.cafeToken,
        tagToken: tagToken
      })
        .then(() => {
          this.closeDialog();
          this.dialog = false;
          // this.fetchMenuList(this.$route.params.cafeToken);
          // if (this.addItemCategoryResult === true) {
          this.showNotification('Категория успешно добавлена', 'success', '2000', 'mdi-check');
          // }
        })
        .catch(() => {
          this.showNotification(`Возникла ошибка: ${this.addItemCategoryErrorMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
        });
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    closeDialog() {
      this.dialog = false;
      this.fetchMenuList(this.$route.params.cafeToken);
    },
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('tag', ['fetchTagList']),
    ...mapActions('tag', ['addCafeTag']),
    ...mapActions('tag', ['deleteCafeTag']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getTagList();
  }
};
</script>
<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
